import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/Layout'

export default function Template(props) {
  const { frontmatter, html } = props.data.markdownRemark
  //console.log(props)
  return (
    <div className="">
      <Layout>
        <div className="flex flex-column items-center w-100">
          <div className='w-80-l w-100 f4 tc pa2'>
            <h1>{frontmatter.title}</h1>
            <h2>{frontmatter.date}</h2>

            {frontmatter.author ?  <h3 className='pa2'>{`by, `+frontmatter.author}</h3>
              : null
            }
          </div>
          <div
            className="blog-post-content pa0 pa3-ns ma0 f6 f5-ns f4-l w-70-l w-100 mb5"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </Layout>
    </div>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        author
      }
    }
  }
`